import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "leading-8 md:leading-6 text-justify mb-6 font-nunito-sans"
}
const _hoisted_2 = { class: "pb-6" }
const _hoisted_3 = { class: "flex w-full border-b-2 border-color-gray-400" }
const _hoisted_4 = { class: "flex-auto text-left font-bold" }
const _hoisted_5 = { class: "hidden md:inline flex-auto text-right text-sm font-semibold" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "relative flex flex-grow-0 flex-wrap py-1 px-0 mt-8 text-gray-900 font-semibold text-xs leading-snug gap-x-1 gap-y-2" }
const _hoisted_8 = {
  key: 0,
  class: "relative flex flex-grow-0 flex-wrap py-1 px-0 mt-8 text-gray-900 font-semibold text-xs leading-snug gap-x-1 gap-y-2"
}
const _hoisted_9 = { class: "flex flex-row justify-around gap-4 flex-wrap md:flex-nowrap" }
const _hoisted_10 = ["href"]
const _hoisted_11 = ["src", "alt"]
const _hoisted_12 = { class: "text-center" }

import { onDeactivated, onMounted, ref } from "vue";
import ArticleService from "@/services/ArticleService";
import Article from "@/types/Article";
import ResponseData from "@/types/ResponseData";
import SingleArticlePageTagItem from "@/components/SingleArticlePageTagItem.vue";
import LogFeErrorsService from "@/services/log-fe-errors-service";
import { useRoute, useRouter } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'Article',
  setup(__props) {

const router = useRouter();

const article = ref<Article>({
  title: "",
  content: "",
  publishedAt: "",
  tags: [],
  mainImage: "",
  slug: "",
});
const relatedArticles = ref<Article[]>([]);

const dateOnly = (dateString: string) => {
  if (!dateString) {
    return "";
  }
  const date = new Date(dateString);
  return (
    date.getDate() + " / " + (date.getMonth() + 1) + " / " + date.getFullYear()
  );
};

const retrieve = (slug: string) => {
  ArticleService.get(slug)
    .then((response: ResponseData) => {
      article.value = response.data.data;
      const summary =
        article.value.content.replace(/<\/?[^>]+(>|$)/g, "").substring(0, 100) +
        " ...";
      document.title = "Pooyan - " + article.value.title || "Pooyan - article";
      document!
        .querySelector('meta[property="og:title"]')!
        .setAttribute("content", article.value.title);
      document!
        .querySelector('meta[property="og:description"]')!
        .setAttribute("content", summary);
      if (article.value.mainImage) {
        document!
          .querySelector('meta[property="og:image"]')!
          .setAttribute("content", article.value.mainImage);
      }
    })
    .catch((e: Error) => {
      // pathFrom is the referrer page (get it from the window object)
      const pathFrom = document.referrer;
      const pathTo = window.location.href;
      LogFeErrorsService.log({
        errorCode: 404,
        errorMessage: e.message,
        pathFrom: pathFrom,
        pathTo: pathTo,
      }).then(() => {
        router.push({ name: "404" });
      });
    });
};

const getRelatedArticles = (slug: string) => {
  ArticleService.getRelated(slug).then((response: ResponseData) => {
    relatedArticles.value = response.data.data;
  });
};

const routeToArticle = (slug: string) => {
  const router = useRouter();
  router
    .push({ name: "Article", params: { slug: slug } })
    // To fix the issue the contents of the page were not re-rendered
    .then(() => {
      window.location.reload();
    });
};

onMounted(() => {
  const route = useRoute();
  retrieve(route.params.slug.toString());
  getRelatedArticles(route.params.slug.toString());
});
onDeactivated(() => {
  document.title = "Pooyan Razian";
  document!
    .querySelector('meta[property="og:title"]')!
    .setAttribute("content", "Pooyan Razian");
  document!
    .querySelector('meta[property="og:description"]')!
    .setAttribute(
      "content",
      "Pooyan Razian's Personal portfolio. A full-stack software developer highly experienced in cloud infrastructure."
    );
  document!
    .querySelector('meta[property="og:image"]')!
    .setAttribute("content", "https://pooyan.info/img/pooyan.png");
});

return (_ctx: any,_cache: any) => {
  return (article.value.title)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, _toDisplayString(article.value.title), 1),
            _createElementVNode("div", _hoisted_5, _toDisplayString(dateOnly(article.value.publishedAt)), 1)
          ]),
          _createElementVNode("div", {
            class: "pt-8",
            innerHTML: article.value.content
          }, null, 8, _hoisted_6),
          _createElementVNode("div", _hoisted_7, [
            _cache[1] || (_cache[1] = _createElementVNode("span", { class: "text-gray-900 dark:text-gray-200 px-4 py-2 gap-y-2 mx-1 whitespace-nowrap" }, "Tags: ", -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(article.value.tags, (tag, index) => {
              return (_openBlock(), _createBlock(SingleArticlePageTagItem, {
                key: index,
                tag: tag.toLowerCase()
              }, null, 8, ["tag"]))
            }), 128))
          ]),
          (relatedArticles.value.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _cache[2] || (_cache[2] = _createElementVNode("span", { class: "text-gray-900 dark:text-gray-200 px-4 py-2 gap-y-2 mx-1 whitespace-nowrap" }, "Related Articles: ", -1)),
                _createElementVNode("div", _hoisted_9, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(relatedArticles.value, (relatedArticle, index) => {
                    return (_openBlock(), _createElementBlock("div", { key: index }, [
                      _createElementVNode("a", {
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (routeToArticle(article.value.slug))),
                        href: 'https://pooyan.info/articles/' + relatedArticle.slug,
                        class: "block cursor-pointer"
                      }, [
                        _createElementVNode("img", {
                          src: relatedArticle.mainImage,
                          class: "img-rounded-full max-h-56",
                          alt: relatedArticle.title
                        }, null, 8, _hoisted_11),
                        _createElementVNode("div", _hoisted_12, _toDisplayString(relatedArticle.title), 1)
                      ], 8, _hoisted_10)
                    ]))
                  }), 128))
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})